import React from 'react'
import { graphql, Link } from 'gatsby'
import { truncate } from 'lodash';
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'
import MediaQuery from 'react-responsive'
import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/layout'
import { getImage } from '../util'
import { formatDate } from '../util/events'

const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  publishedOn,
  featuredImage,
  author,
  recentPosts,
  next,
  prev
}) => {
  const PostContent = contentComponent || Content

  const recentPostCards = recentPosts.map(post => {
    post = post.node
    return (
      <div className="card recent-news horizontal" key={`recent-post-${post.id}`}>
        <figure>
          {getImage(post.featuredImage, post.title)}
        </figure>
        <div className="card-section">
          <p className="post-date">{formatDate(post.publishedOn)}</p>
          {post.fields ?
            (<h6>
              <Link to={post.fields.slug}>{post.title}</Link>
            </h6>) : (<h6>{post.title}</h6>)
          }
        </div>
      </div>
    )
  })

  return (
    <section className="section grid-container">
      <div className="grid-x grid-margin-x content">
        <div className="cell small-12 medium-8 news-post-container">
          {getImage(featuredImage, title)}
          <p className="post-date post-content-date">{formatDate(publishedOn)}</p>
          <h1 className="post-title">
            {title}
          </h1>
          <PostContent content={content} className="post-content" />
          <p className="author-byline">Posted by: {author.name}</p>
          <nav aria-label="pagination" className="next-previous">
            {prev && (
              <div className="previous-pagination">
                <Link to={prev.fields.slug} className="return-link" aria-label={`Previous article: ${prev.title}`}>
                  <i className="fa fa-chevron-left left-chevron" aria-hidden="true" />
                  <MediaQuery minWidth="40rem">
                    <span>{prev.title}</span>
                  </MediaQuery>
                  <MediaQuery maxWidth="40rem">
                    <span>Previous</span>
                  </MediaQuery>
                </Link>
              </div>
            )}

            {next && (
              <div className="next-pagination">
                <Link to={next.fields.slug} className="return-link" aria-label={`Next article: ${next.title}`}>
                  <MediaQuery minWidth="40rem">
                    <span>{next.title}</span>
                  </MediaQuery>
                  <MediaQuery maxWidth="40rem">
                    <span>Next</span>
                  </MediaQuery>
                  <i className="fa fa-chevron-right right-chevron" aria-hidden="true" />
                </Link>
              </div>
            )}
          </nav>
          <Link to="/news" className="return-link" aria-label="Return to all news articles">
            <i className="fa fa-chevron-left left-chevron" aria-hidden="true" />
            <span>Back to News</span>
          </Link>
        </div>
        <aside className="cell small-12 medium-4" aria-labelledby="recentNewsHeading" >
          <h3 className="recent-news-header" id="recentNewsHeading">Recent News</h3>
          {recentPostCards}
        </aside>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  publishedOn: PropTypes.string,
  featuredImage: PropTypes.object,
  author: PropTypes.object,
  recentPosts: PropTypes.array,
}

const BlogPost = ({ data, pageContext }) => {
  const { post } = data
  const { edges: recentPosts } = data.recentPosts

  const content = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(post.content)
    .toString()

  //This checks for a post summary. If none is found it sets the description to the beginning of the content stripped of HTML
  const description = post.summary ? truncate(post.summary, { 'length': 200, 'omission': '\u2026' }) : truncate(content.replace(/<.*?>/g, ""), { 'length': 200, 'omission': '\u2026' })

  const meta = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { property: 'og:title', content: post.title },
    { property: 'og:description', content: description },
  ]

  if (post.featuredImage && post.featuredImage.name) {
    meta.push({ property: 'og:image', content: process.env.GATSBY_WEBSITE_URL + post.featuredImage.name })
    meta.push({ name: 'twitter:image:alt', content: post.featuredImage.altText || post.title })
  }

  return (
    <Layout>
      <Helmet
        title={`${post.title} | Fluxible: Canada’s UX Festival`}
        meta={meta}
      />
      <BlogPostTemplate
        content={content}
        contentComponent={HTMLContent}
        title={post.title}
        publishedOn={post.publishedOn}
        featuredImage={post.featuredImage}
        author={post.author}
        recentPosts={recentPosts}
        next={pageContext.next}
        prev={pageContext.prev}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object,
    recentPosts: PropTypes.object,
  }),
}

export default BlogPost

export const blogPostQuery = graphql`
  query PostByID($id: String!) {
    post: fluxibleBlogPosts(id: { eq: $id }) {
      id
      title
      summary
      content
      publishedOn
      featuredImage {
        name
        altText
        contentType
      }
      author {
        name
      }
    }
    recentPosts: allFluxibleBlogPosts(limit: 5, filter:{title:{ne:""}}, sort: {fields: [publishedOn], order: DESC}) {
        edges {
          node {
            id
            title
            publishedOn
            fields {
                slug
            }
            featuredImage {
                name
                altText
                contentType
              }
          }
        }
    }
  }
`
